import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g13'

let batchAddUrl = 'batchAdd'
let aggregationUrl = 'aggregation'
let generateDataUrl = 'generateData'
let tenderStatUrl = 'tenderStats'
let detailTenderStatUrl = 'detailTenderStats'

class G13Service extends RestService {
  batchAdd (g13Models) {
    g13Models.forEach(g13Model => {
      g13Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g13Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDetailTenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, detailTenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  generateData (planStatExaminationGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, `${generateDataUrl}?planStatExaminationGuid=${planStatExaminationGuid}`), {}, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  aggregation (tenderGuid, year, month) {
    return axios.get(utility.getRestFullUrl(this.resourceName, aggregationUrl), {
      params: {
        tenderGuid,
        year,
        month
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g13Service = new G13Service(resourceName)

export default g13Service
